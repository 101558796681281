import Container from 'components/Layout/Container'
import React from 'react'
import { Button, Flex } from '@pancakeswap/uikit'


interface Props {
  title1?: string,
  title2?: string
}

const Apply: React.FC<Props> = ({ title1, title2 }) => {


  return (
    <section className="apply">
      <Container className="container">
        <Flex alignItems='center' justifyContent="center" flexDirection="column">

          <h2 className="fs-1 o-color apply__title">Have Any Project?</h2>
          <div className='apply__sub-title'>Apply For IDO/INO</div>
          <Button className="btn-apply" width='180px' height="60px">Apply</Button>
        </Flex>
      </Container>
    </section>
  )
}

export default Apply
