import { Flex } from '@pancakeswap/uikit'
import { renderSocialIcon } from 'components/CardItem'
import CountDownRender from 'components/CountDownRender'
import Container from 'components/Layout/Container'
import React from 'react'
import Countdown from 'react-countdown'
import { formatDate } from 'utils/utilsFun'

interface Props {
  loading?: boolean,
  data?: any
}
const IMG_DOMAIN = process.env.NEXT_PUBLIC_THUMB_URL

const FeaturesHome: React.FC<Props> = ({ data }) => {
  const {
    thumbnail,
    initial_offer,
    start_at,
    end_at,
    name,
    social_medias,
    _id,
    total_deposit,
    launchpads
  } = data


  const timeStart = formatDate(start_at, "timeStamp")
  const timeEnd = formatDate(end_at, "timeStamp")
  const DATE_NOW = new Date().getTime();

  const isComing = DATE_NOW < Number(timeStart)

  const _price = launchpads.length > 0 ? parseFloat(parseFloat(launchpads[0].price_chain).toFixed(5)) : 0 

  const rendererCountDown = ({ days, hours, minutes, seconds, completed }) => <CountDownRender data={{ days, hours, minutes, seconds, completed, title: isComing ? "COMING SOON" : 'SALE END IN' }} />

  return (
    <Container className="container">
      <section className="features">
        <div className="features__thumb">
          <a href={`launchpad/${_id}`}>
            <img alt={name} src={`${IMG_DOMAIN}${thumbnail}`} />
          </a>
        </div>
        <div className="features__inf">
          <a href={`launchpad/${_id}`} className="a-hover">
            <div className='fs-2 w-color title'>{name}</div></a>
          <Flex className="ido-info" alignItems='center'>
            <div className='ido-info__name'>{initial_offer}</div>
            <div className='ido-info__time'>{formatDate(start_at)}</div>
          </Flex>
          <Flex className='inf-social' alignItems='center' >
            {
              Object.keys(social_medias).map((_) => renderSocialIcon(_, social_medias[_]))
            }
          </Flex>
          <Flex alignItems='center' justifyContent='space-between' className='box-supply'>
            <div className="total-supply">
              <div className='total-supply__name'>Total supply</div>
              <div className='total-supply__value'>{total_deposit?.total_deposit}</div>
            </div>
            <div className='features-price'>
              <div className='features-price__name'>Price</div>
              <Flex alignItems='center'>
                <img alt="ourAdvisor" src="/images/token-bnb.png" className="img-fluid" />
                <div className='features-price__value'>{_price}</div>
              </Flex>
            </div>
          </Flex>
          <Countdown date={new Date(isComing ? timeStart : timeEnd)} renderer={rendererCountDown} />
        </div>
      </section>
    </Container>
  )
}

export default FeaturesHome
