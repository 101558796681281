import React from 'react'
import { useRouter } from 'next/router'
import Container from 'components/Layout/Container'
import CardItem from 'components/CardItem'

interface Props {
  dataSource?: any
}

const AllProject: React.FC<Props> = ({ dataSource }) => {
  const route = useRouter()

  const _renderItem = () => {
    return dataSource?.projects.map((_, idx) => <CardItem data={_} key={`item-${idx}`} />)
  }

  const _onViewAll = () => {
    route.push(`/launchpad?page=1&search=&type=&cid=`)
  }

  return (
    <section className="all-project">
      <Container className="container">
        <div className="top-header-nav">
          <h2 className="fs-1"><span className="o-color fs-1">All</span> Project</h2>
          <div className="view-all" onClick={_onViewAll}>View all &gt;&gt;</div>
        </div>

        <div className="all-items">
          {_renderItem()}
        </div>
      </Container>
    </section>
  )
}

export default AllProject
