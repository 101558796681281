import styled from 'styled-components'

const HomePages = styled.div`  

  .features{
    display:flex;
    gap:70px;
    padding-top:75px;
    padding-bottom:40px;
    &__thumb{
      border-radius:18px;
      background: #212936;
      padding:16px;
      max-width:550px;
      img{
        border-radius:16px;
      }
    }
    .box-supply{
      .total-supply{
        &__name{
          font-weight:500;margin-bottom:11px;
        }
        &__value{
          font-size:20px;
          font-weight:700;
        }
        
      }
      .features-price{
        &__name{
          font-weight:500;
          margin-bottom:10px;
        }
        &__value{
          font-size:20px;
          font-weight:700;
          margin-left:4px;
        }
      }
      padding:40px 0;
    }
   
  }
  .features.loading{
    display:grid ;
    grid-template-columns:1fr 1fr;
    gap:70;
    height:400px;
  }

  .project-slider{
      &__content{
        padding:0 46px;
      }            
      &__content {
        .card {
          margin-bottom:60px;
        }
        .swiper-pagination-bullet{
          width:10px;
          height:10px;
          background:transparent;
          border:1px solid #fff;
        }
        .swiper-pagination-bullet-active {
          background:#fff;
          border:1px solid #fff;
        }      

      }
    }

  .launch-about{
    margin-bottom: 40px;
    &__inf{
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 35px;
      padding:0 46px;
    }
  }
  .launch-about-item{
    background:#212936 ;
    border-radius:20px;
    padding: 25px 30px;
    .thumb{
      margin-bottom:25px;
    }
    .title{
      margin-bottom:19px;
    }
    .desc{
      margin-bottom:19px;
      font-weight:400;
    }
  }

  .read-more {
    a {
      font-size:12px;
      font-weight:700;
      text-transform: uppercase;
      color: #F58227;
      display:block;
    }    
  }

  .community{
    background:#212936 ;
    padding:50px 0;
    margin:40px 0;
    .community-out{
      display:flex ;
      align-items:center;
      justify-content: center;
      gap:70px;
      .title-1 {
        text-transform: uppercase;
        font-size: 16px;
        font-weight: 500;
        color: #212936;
        text-shadow: 1px 1px 0 #fff, -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff;
      }
      .title-2{
        margin-bottom:0;
        line-height:40px;
      }
      .item-right{
        display:grid ;
        grid-template-columns: repeat(2,1fr);
        gap:70px;
        row-gap:30px;
        flex-wrap:wrap;
        .item-social {
          display: flex;
          align-items:center; 
          gap:20px;        
          &__icon{
            width:60px;
            height:60px;
            display:flex;
            justify-content:center;
            align-items:center;
            background:#F58227 ;
            border-radius:30px; 
          }
          .item-social-title{
            font-weight:500;
          }
        }
      }
    }
  }

  .all-project{
   .all-items{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    column-gap: 46px;
    row-gap: 40px;
    }
    margin-bottom:40px;
  }

  .top-header-nav{
    display:flex ;
    align-items:center;
    justify-content:space-between;
    .view-all{
      font-weight: 700;
      font-size: 14px;
      line-height: 17px;
      text-transform: uppercase;
      color: #F58227;
      display: block;
      text-align: right;
      cursor: pointer;
      padding: 10px 0;
    }
  }
  .apply{
    padding:40px 0;
    background:#212936;
    &__title{
      margin-bottom:9px;
    }
    &__sub-title{
      font-weight: 700;
      font-size: 24px;
      line-height: 29px;
      color: #FFFFFF;
      margin-bottom: 64px;
    }
    .btn-apply{
      background-color:#F58227;
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
      text-transform: uppercase;
      border-radius:30px;
    }
  }

  @media screen and (min-width: 576px) and (max-width: 1024px) {
    .features, .features.loading{
      gap:30px;
    }
    .project-slider {
      .project-slider__content {
        padding:0
      }
    }
   .launch-about {
      &__inf{
        grid-template-columns:repeat(2,1fr);
        padding:0;
      }
    }
    .community {
      padding: 30px 0;
      .community-out {
        gap: 50px;
        .item-right{
          grid-template-columns: repeat(1,1fr);
        }
      }
    }
    .all-project {
      .all-items {
        grid-template-columns: repeat(2,1fr);
        column-gap: 30px;
      }
    }
  }

  @media screen and (max-width: 575px) {
     .features{
      flex-direction:column;
      gap: 30px;
      padding-top: 30px;
      &__thumb{
        padding:10px;
      }
     }
     .features.loading {
        grid-template-columns:1fr;
        height:auto ;
        .features__thumb{
          height:365px ;
        }
     }
     .project-slider {
      .project-slider__content {
        padding:0
      }
     }

     .launch-about {
      &__inf{
        grid-template-columns:repeat(1,1fr);
        padding:0;
      }
     }
    .community {
      padding: 30px 0;
      .community-out {
        flex-direction:column;
        gap: 50px;
        .item-right{
          grid-template-columns: repeat(1,1fr);
        }
      }
    }
    .all-project {
      .all-items {
        grid-template-columns: repeat(1,1fr);
        column-gap: 30px;
      }
    }
  }

`

export default { HomePages }
