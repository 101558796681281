import { PageMeta } from 'components/Layout/Page'
import { memo } from 'react'
import AllProject from './components/AllProject'
import Apply from './components/Apply'
import Community from './components/Community'
import FeaturesHome from './components/FeaturesHome'
import LaunchAbout from './components/LaunchAbout'
import ProjectSlider from './components/ProjectSlider'
import style from './components/styles'

interface Props {
  data?: any
}

const Home: React.FC<Props> = ({ data }) => {
  const dataFeature = data?.find(_ => _.category_slug === "highlight")
  const dataTrending = data?.find(_ => _.category_slug === "trending")
  const dataIDO = data?.find(_ => _.category_slug === "upcoming_ido")
  const allProject = data?.find(_ => _.category_slug === "all_projects")

  return (
    <style.HomePages>
      <PageMeta />
      <FeaturesHome data={dataFeature}/>
      <ProjectSlider title1="TRENDING" dataSource={dataTrending} />
      <LaunchAbout title1="Why" title2="launch with us" />
      <ProjectSlider title1="Upcoming" title2="IDO" dataSource={dataIDO} />
      <Community />
      <AllProject dataSource={allProject}/>
      <LaunchAbout title1="what" title2="we offer " />
      <Apply />
    </style.HomePages>
  )
}

export default memo(Home)


