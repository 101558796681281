import { Flex, MediumIcon, Text } from '@pancakeswap/uikit';
import React from 'react';
import { formatDate } from 'utils/utilsFun';


interface Props {
  data?: any,
  addClass?: string,
  path?: string,
  loading?: boolean
}

export const renderSocialIcon = (key, val) => {
  switch (key) {
    case 'facebook':
      return <a className={key} target="_blank" href={val} rel="noopener noreferrer">
        <i className="fab fa-facebook-square" />
      </a>
    case 'linkedin':
      return <a className={key} target="_blank" href={val} rel="noopener noreferrer">
        <i className="fab fa-linkedin" />
      </a>
    case 'twitter':
      return <a className={key} target="_blank" href={val} rel="noopener noreferrer">
        <i className="fab fa-twitter" />
      </a>
    case 'telegram':
      return <a className={key} target="_blank" href={val} rel="noopener noreferrer">
        <i className="fab fa-telegram" />
      </a>
    case 'discord':
      return <a className={key} target="_blank" href={val} rel="noopener noreferrer">
        <i className="fab fa-discord" />
      </a>
    case 'medium':
      return <a className={key} target="_blank" href={val} rel="noopener noreferrer">
        <MediumIcon />
      </a>
    case 'google':
      return <a className={key} target="_blank" href={val} rel="noopener noreferrer">
        <i className="fab fa-google-plus" />
      </a>
    default:
      return <></>

  }
}

const IMG_DOMAIN =  process.env.NEXT_PUBLIC_THUMB_URL

const CardItem: React.FC<Props> = ({ data, addClass }) => {  

  const price = data.first_min_launchpad?.prices ? data.first_min_launchpad?.prices?.price_chain : 0

  return <div className={`card ${addClass || ''}`}>
    <div className="card__thumb">
      <a href={`launchpad/${data?._id}`}>
        <img alt={data?.name} src={`${IMG_DOMAIN}${data?.thumbnail}`} className="img-fluid" />
      </a>
    </div>
    <div className="card__inf">
      <a href={`launchpad/${data?._id}`} className="a-hover">
        <div className='fs-4 w-color title'>{data.name} </div></a>
      <Flex className="ido-info" alignItems='center'>
        <div className='ido-info__name'>{data.initial_offer || ""}</div>
        <div className='ido-info__time'>{formatDate(data.start_at)} </div>
      </Flex>
      <Flex className='inf-social' alignItems='center' >
        {
          Object.keys(data.social_medias).map((_) => renderSocialIcon(_, data.social_medias[_]))
        }

      </Flex>
      <Flex alignItems='center' justifyContent='space-between' className='card__price'>
        <Text fontSize="16px" color="#E5E9F0">Sell price</Text>
        <Flex alignItems='center'>
          <img alt="ourAdvisor" src="/images/token-bnb.png" className="img-fluid" />
          <div className='card__inf__price'>{`${parseFloat(Number(price).toFixed(5))}`}</div>
        </Flex>
      </Flex>
    </div>
  </div>
}

export default CardItem