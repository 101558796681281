import React from 'react'
import styled from 'styled-components'
import { Flex } from '@pancakeswap/uikit'

interface Props {
  data?: any
}

const formatNumber = (val: number, label: string) => {
  const textVal = val.toString()
  
  const subText1 = textVal.slice(0, 1)
  const subText2 = textVal.slice(-1)
  if (val > 9) {
    return _renderItemTime(subText1, subText2, label)
  }
  return _renderItemTime(0, val, label)
}

const _renderItemTime = (time1, time2, label) => {
  return <div className='el-time'>
    <Flex className='el-time__items'>
      <div className="el-time__items__child">{time1}</div>
      <div className="el-time__items__child">{time2}</div>
    </Flex>
    <div className="type-time">{label}</div>
  </div>
}


const CountDownRender: React.FC<Props> = ({ data }) => {

  const { days, hours, minutes, seconds, completed, title } = data

  if (completed) return <Wrapper>
    <div className="box-countdown__title">Ended</div>
    <div className="box-countdown__inf">      
      {formatNumber(0, 'Hour')}
      {formatNumber(0, 'minute')}
      {formatNumber(0, 'second')}
    </div>
  </Wrapper>
  return <Wrapper>
    <div className="box-countdown__title">{title}</div>
    <div className="box-countdown__inf">
      {days > 0  && formatNumber(days, 'Day')}
      {formatNumber(hours, 'Hour')}
      {formatNumber(minutes, 'minute')}
      {formatNumber(seconds, 'second')}
    </div>
  </Wrapper>


}

export default CountDownRender

const Wrapper = styled.div`
   .box-countdown{
      &__title {
        text-transform:uppercase;
        font-size:20px;
        font-weight:700;
        color:#121b24;
        margin-bottom:16px ;
        text-shadow:
            1px  1px 0 #F58227,
            -1px -1px 0 #F58227,  
            1px -1px 0 #F58227,
            -1px  1px 0 #F58227,
            1px  1px 0 #F58227;
      }
      &__inf{
        display:flex ;
        gap:10px;
        .el-time{   
          .type-time {
            font-size:12px;
            text-align:center;
            margin-top:6px;
          }
          &__items {
            display:flex;
            align-items:center;
            justify-content:center;
            gap:5px;
            &__child {
              width:40px;
              height:50px;
              display:flex;
              align-items:center;
              justify-content:center;
              font-weight: 700;
              font-size: 20px;
              line-height: 24px;
              text-transform: uppercase;
              background:url("/images/bg-countdown.png") no-repeat ;
            }
          }
        }
      }
    }
`