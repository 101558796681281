import React from 'react'
import Container from 'components/Layout/Container'
import { Flex, Text, TwitterIcon, TelegramIcon, DiscordIcon } from '@pancakeswap/uikit'


const Community: React.FC = () => {




  return (
    <section className="community">
      <Container className="container">
        <div className="community-out">
          <div className="item-left">
            <div className="title-1">Our strong</div>
            <div className="title-2 fs-1 o-color">community</div>
          </div>
          <div className="item-right">
            <div className="item-social">
              <div className="item-social__icon">
                <TwitterIcon />
              </div>
              <div className="item-social__inf">
                <div className="number-follow fs-2">+100k</div>
                <div className="item-social-title">
                  TWITTER FOLLOWERS
                </div>
              </div>
            </div>
            <div className="item-social">
              <div className="item-social__icon">
                <TwitterIcon />
              </div>
              <div className="item-social__inf">
                <div className="number-follow fs-2">+100k</div>
                <div className="item-social-title">
                  TWITTER FOLLOWERS
                </div>
              </div>
            </div>
            <div className="item-social">
              <div className="item-social__icon">
                <TwitterIcon />
              </div>
              <div className="item-social__inf">
                <div className="number-follow fs-2">+100k</div>
                <div className="item-social-title">
                  TWITTER FOLLOWERS
                </div>
              </div>
            </div>
          </div>
        </div>

      </Container>
    </section>
  )
}

export default Community
