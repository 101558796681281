import Container from 'components/Layout/Container'
import React from 'react'


const DATA = [
  {
    image: 'images/home/icon-1.png',
    title: 'Tiêu chí chọn dự án',
    desc: 'Tiềm năng lợi nhuận cao hơn rủi ro, có tiềm lực mạnh'
  },
  {
    image: 'images/home/icon-2.png',
    title: 'Tiêu chí chọn dự án',
    desc: 'Tiềm năng lợi nhuận cao hơn rủi ro, có tiềm lực mạnh'
  },
  {
    image: 'images/home/icon-3.png',
    title: 'Tiêu chí chọn dự án',
    desc: 'Tiềm năng lợi nhuận cao hơn rủi ro, có tiềm lực mạnh'
  },
  {
    image: 'images/home/icon-4.png',
    title: 'Tiêu chí chọn dự án',
    desc: 'Tiềm năng lợi nhuận cao hơn rủi ro, có tiềm lực mạnh'
  },
  {
    image: 'images/home/icon-5.png',
    title: 'Tiêu chí chọn dự án',
    desc: 'Tiềm năng lợi nhuận cao hơn rủi ro, có tiềm lực mạnh'
  }
]

interface Props {
  title1?: string,
  title2?: string
}

const LaunchAbout: React.FC<Props> = ({ title1, title2 }) => {

  const renderItem = () => {
    return DATA.map((_, id) => {
      return <div className="launch-about-item" key={`launch-${id.toString()}`}>
        <div className="thumb"><img src={_.image} alt="" /></div>
        <div className="title fs-5">{_.title}</div>
        <div className="desc">{_.desc}</div>
        <div className="read-more text-right">
          <a href="#"> Read more</a>
        </div>
      </div>
    })

  }

  return (
    <Container className="container">
      <section className="launch-about">
        <h2 className="fs-1"><span className="fs-1 o-color">{title1}</span><span className="fs-1 w-color">{` ${title2}`}</span></h2>
        <div className="launch-about__inf">
          {renderItem()}
        </div>
      </section>
    </Container>
  )
}

export default LaunchAbout
