import { getApi } from 'utils/apiHelper'
import Home from '../views/Home'

export const getServerSideProps = async () => {
  const res = await getApi('projects/list_home').then((resp) => {
    const dataHighlight = { ...resp.data.highlight, category_slug: 'highlight' }
    return resp.data.data.concat(dataHighlight)
  }).catch(() => {
    return null
  })

  return {
    props: {
      allProject: res
    }
  }
}


const IndexPage = ({ allProject }) => {
  return <Home data={allProject} />
}



export default IndexPage
