import CardItem from 'components/CardItem'
import Container from 'components/Layout/Container'
import { useRouter } from 'next/router'
import React, { memo } from 'react'
import { Navigation, Pagination } from 'swiper'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'
import { Swiper, SwiperSlide } from 'swiper/react'

interface Props {
  title1?: string,
  title2?: string,
  dataSource: any
}

const ProjectSlider: React.FC<Props> = ({ title1, title2, dataSource }) => {
  const route = useRouter()
  const _renderItem = () => {
    return (
      dataSource?.projects.map((_) => <SwiperSlide key={_._id}><CardItem data={_} /></SwiperSlide>)
    )
  }

  const _onViewAll = () => {
    route.push(`/launchpad?page=1&search=&type=&cid=${dataSource?.category_id
      }`)
  }

  return (
    <section className="project-slider">
      <Container className="container">
        <div className="top-header-nav pb-30">
          <h2 className="fs-1">
            <span className="fs-1 o-color">{title1}</span>{title2 && <span className="fs-1 w-color">{` ${title2}`}</span>}
          </h2>
          <div className="view-all" onClick={_onViewAll}>View all &gt;&gt; </div>
        </div>

        <div className="project-slider__content">
          <Swiper
            className="mySwiper"
            modules={[Pagination, Navigation]}
            pagination={{
              clickable: true,
            }}

            slidesPerView={3}

            breakpoints={{
              320: {
                slidesPerView: 1,
                spaceBetween: 15,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 24,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 46,
              },
            }}
          >
            {_renderItem()}

          </Swiper>
        </div>
      </Container>
    </section>
  )
}

export default ProjectSlider
